import * as axios from "axios";
import { ArticleExtraData } from "articlePage/stateInterfaces";
import { IFieldDisplayData } from "widgets/commonDataObjects/interfaces";
import { addAxiosDateTransformerSettings } from "../../../../../utils/network/axiosDateTransformer";
import { ISlot } from "./storiesAutoWrapper";
import Slider from 'react-slick'
import { StoriesAutoMobileSlotItem } from "./storiesAutoMobileSlotItem";
import { StoriesItemMobile } from "./storiesItemMobile";
import { disablePageRefresh, enablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import { Splide, SplideSlide } from '@splidejs/react-splide';

interface StoriesAutoMobileComponentaProps {
    componentaId?: string
    fieldsDisplaySettings: IFieldDisplayData
    hideVideoAds?: boolean
    tabBackgroundColor?: string
    slots: ISlot[]
    storiesProgressTime: number
    tabTitle: string
}

interface StoriesAutoMobileComponentaState {
    storyEntities: StoriesEntity[]
    isStoriesDisplay: boolean
    activeEntityIndex: number
}

export interface StoriesEntity extends ISlot {
    linkedItems: ArticleExtraData[]
    slotId: string
    isAllStoriesFinished: boolean
}

export const DICT = {
    "The link has been copied to the clipboard": "تم نسخ الرابط إلى الحافظة"
}

export class StoriesAutoMobileComponenta extends React.Component<StoriesAutoMobileComponentaProps, StoriesAutoMobileComponentaState> {
    public static siteScriptName = "StoriesAutoMobileComponenta"
    constructor(props) {
        super(props)
        this.state = {
            storyEntities: [],
            isStoriesDisplay: false,
            activeEntityIndex: 0,
        }
    }

    private splideSliderRef;

    componentDidMount() {
        this.initializeComponent();
    }

    private initializeComponent = async () => {
        await this.getArticles();
        this.handleFinishedStories();
    }

    private getArticles = async () => {
        const { componentaId, slots } = this.props;
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        try {
            const articleRequests = slots.map(async (item, index) => {
                const response = await axiosInstance.get(`/iphone/json/api/article_list/${componentaId}/type/${item.contentSource.type}/id/${item.contentSource.link.id}`);
                // @ts-ignore
                const linkedItems = response.data.data as ArticleExtraData[];
                return {
                    ...item,
                    linkedItems,
                    slotId: item.slotId,
                    isAllStoriesFinished: false,
                };
            });
            const allArticles: StoriesEntity[] = await Promise.all(articleRequests);
            this.setState({ storyEntities: allArticles });
        } catch (error) {
            console.error("Error getting headline data:", error);
        }
    }

    private onStoriesOpen = (clickedIndex: number) => {
        this.setState({ isStoriesDisplay: true, activeEntityIndex: clickedIndex });
        disablePageRefresh();
    }

    private onStoriesClose = () => {
        this.setState({ isStoriesDisplay: false });
        enablePageRefresh();
    }

    private handleNextSlide = () => {
        const {activeEntityIndex, storyEntities} = this.state;
        if(activeEntityIndex === storyEntities.length - 1) return;
        this.splideSliderRef.go(activeEntityIndex + 1);
        this.setState({ activeEntityIndex: this.state.activeEntityIndex + 1 });
    }

    private handlePrevSlide = () => {
        const {activeEntityIndex} = this.state;
        if(activeEntityIndex === 0) return;
        this.splideSliderRef.go("<");
        this.setState({ activeEntityIndex: this.state.activeEntityIndex - 1 });
    }

    private handleFinishedStories = () => {
        const { storyEntities } = this.state;
        const updatedStoryEntities = storyEntities.map((entity) => {
            const storyCount = localStorage.getItem(entity.slotId);
            const isAllStoriesFinished = +storyCount === entity.linkedItems.length - 1 || entity.isAllStoriesFinished;
            return {
                ...entity,
                isAllStoriesFinished,
            }
        })

        this.setState({ storyEntities: updatedStoryEntities });
    }

    private onAllStoriesEnd = (nextSlideIndex: number) => {
        const { storyEntities } = this.state;
        if (nextSlideIndex > storyEntities.length - 1) return;
        this.splideSliderRef.go(nextSlideIndex);
        this.setState({ activeEntityIndex: nextSlideIndex });
    }

    private onSlideMove = (index: number) => {
        this.setState({activeEntityIndex: index});
    }

    public render() {
        const { fieldsDisplaySettings, tabTitle, storiesProgressTime, slots } = this.props;
        const { storyEntities, isStoriesDisplay, activeEntityIndex } = this.state;

        const mobileSettings = {
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows: false,
            swipeToSlide: true,
            rtl: slots.length > 5,
        };

        const storiesOptions = {
            type: 'slide',
            speed: 800,
            perPage: 1,
            perMove: 1,
            trimSpace: false,
            arrows: false,
            pagination: false,
            start: activeEntityIndex,
            gap: "1em",
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
            direction: "rtl",
        };

        return (
            <div className="storiesAutoMutamComponenta">
                {fieldsDisplaySettings.isTabVisible &&
                    <div className="storiesAutoComponentaTab componentaTab">
                        <div className="tabTitle">{tabTitle}</div>
                    </div>
                }
                <div className="galleryWrapper">
                    <Slider {...mobileSettings} className="slider">
                        {storyEntities.map((entity, index) =>
                            <StoriesAutoMobileSlotItem
                                key={entity.slotId}
                                entity={entity}
                                index={index}
                                onStoriesOpen={this.onStoriesOpen}
                                isAllStoriesFinished={entity.isAllStoriesFinished}
                            />)}
                    </Slider>
                </div>
                {isStoriesDisplay &&
                    <div className="storiesDisplayWrapper">
                        <Splide
                            options={storiesOptions}
                            ref={ref => this.splideSliderRef = ref}
                            onMove={(dest: number, newIndex: number) => this.onSlideMove(newIndex)}
                        >
                            {storyEntities.map((entity, index) => (
                                <SplideSlide>
                                    <StoriesItemMobile
                                        key={entity.slotId}
                                        entity={entity}
                                        index={index}
                                        onStoriesClose={this.onStoriesClose}
                                        activeEntityIndex={activeEntityIndex}
                                        storiesProgressTime={storiesProgressTime}
                                        handleNextSlide={this.handleNextSlide}
                                        handlePrevSlide={this.handlePrevSlide}
                                        onAllStoriesEnd={this.onAllStoriesEnd}
                                        handleFinishedStories={this.handleFinishedStories}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                }
            </div >
        )
    }
}