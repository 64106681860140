import Slider from "react-slick";
import { SiteItemData } from "../../../../interfaces";
import { VideoGalleryVerticalItemMutam } from "./videoGalleryVerticalItemMutam";
import { VideoVerticalFeedItemMutam } from "./videoVerticalFeedItemMutam";
import { PromoCarousel } from "./promoCarousel";
import { enablePageRefresh, disablePageRefresh } from "../../../../siteWidgets/siteWidgetUtils";
import * as ResizeObserver from "resize-observer-polyfill";
import { categoryObj } from "./videoGalleryVerticalMutamComponenta";

interface VideoGalleryVerticalMutamProps {
    videoList: SiteItemData[]
    isTabVisible: boolean
    tabTitleText: string
    repeatAds: number
    maxAds: number
    firstAdPlace: number
    shouldDisplayMobileAds: boolean
    category: categoryObj
    hideMoreButton: boolean
    moreButtonText: string
    domain: string
    shouldDisplayAdsenseAds: boolean
}

interface VideoGalleryVerticalMutamState {
    feedActiveSlide: number
    isFeedDisplay: boolean
    initialFeedSlide: number
    togglePromoActive: boolean
    currentPromoIndex: number
    promoViewportWidth: number
    lastIntersectedPromoIndex: number
}

export class VideoGalleryVerticalMutam extends React.Component<VideoGalleryVerticalMutamProps, VideoGalleryVerticalMutamState> {
    constructor(props) {
        super(props);
        this.state = {
            initialFeedSlide: 0,
            feedActiveSlide: 0,
            isFeedDisplay: false,
            togglePromoActive: false,
            currentPromoIndex: 0,
            promoViewportWidth: 375,
            lastIntersectedPromoIndex: 0,
        }
    }

    private componentWrapperRef;
    private resizeObserver: ResizeObserver;
    private sliderRef;


    componentDidMount() {
        this.resizeObserver = new ResizeObserver(entries => entries.forEach(e => {
            this.setState({ promoViewportWidth: e.contentRect.width })
        }));
        this.resizeObserver.observe(this.componentWrapperRef);
    }

    componentWillUnmount() {
        this.resizeObserver && this.resizeObserver.disconnect();
    }

    private onOpenVideoGalleryView = (originalVideoListIndex: number) => {
        const { videoList } = this.props;
        const initialFeedSlide = this.getListWithAds().findIndex((element) => element.itemId === videoList[originalVideoListIndex].itemId);
        this.setState({ initialFeedSlide: initialFeedSlide, feedActiveSlide: initialFeedSlide }, () => { this.setState({ isFeedDisplay: true }) });
        disablePageRefresh();
    }

    private onMoreVideosHandler = () => {
        this.setState({ initialFeedSlide: 0, feedActiveSlide: 0, isFeedDisplay: true });
    }

    private onCloseVideoGalleryView = () => {
        this.setState({ isFeedDisplay: false });
        enablePageRefresh();
    }

    private onVideoEnded = () => {
        this.setState({ togglePromoActive: !this.state.togglePromoActive });
    }

    private getItemIntoViewIndex = (itemIntoViewIndex: number) => {
        this.setState({ currentPromoIndex: itemIntoViewIndex })
    }
    private onLastIntersectingPromoCHange = (lastPromoIndex: number) => {
        this.setState({ lastIntersectedPromoIndex: lastPromoIndex })
    }

    private onChangeVideoHandler = () => {
        setTimeout(() => {
            const videoElement = document.querySelector(".videoGalleryVerticalMutamComponenta .feedDisplay .slick-active .videoVerticalFeedItemMutam #player.is-paused .fp-engine") as HTMLVideoElement;
            if (videoElement) {
                videoElement.currentTime = 0;
                videoElement.click();
            };
        }, 600)
    }

    private onStopPlayingVideoHandler = () => {
        const playingVideoElement = document.querySelector(".videoGalleryVerticalMutamComponenta .feedDisplay .slick-slide:not(.slick-active) .videoVerticalFeedItemMutam #player.is-playing .fp-engine") as HTMLVideoElement;
        if (playingVideoElement) {
            playingVideoElement.currentTime = 0;
            playingVideoElement.pause();
        };
    }

    private onNextSlide = () => {
        this.onChangeVideoHandler();
        this.sliderRef.slickNext();
        this.pushGA4Event("swipe_up", true);
    }

    private pushGA4Event = (action: string, isFeedDisplay: boolean) => {
        if (typeof window == "undefined" || !window.dataLayer) return;
        const { category } = this.props;
        const actionPrefix = category && category.name ? (category.name === "דף הבית") ? "homepage" : category.name : "homepage";
        const gaAction = isFeedDisplay ? `video_${action}` : `${actionPrefix}_${action}`;

        window.dataLayer.push({
            event: "content_click",
            vertical_video_action: gaAction,
        });
    };

    private getListWithAds = () => {
        const { repeatAds, maxAds, firstAdPlace, videoList, shouldDisplayMobileAds } = this.props;
        const videoListCopy = [...videoList];
        const videoListLength = videoListCopy.length;
        let numberOfAds = firstAdPlace > videoListLength ? 0 : Math.min(maxAds, videoListLength - firstAdPlace, Math.floor((videoListLength - firstAdPlace) / repeatAds + 1));
        const newListLength = videoListLength + numberOfAds;
        const baseId = "ads.vertical.native.";
        let newList = [];
        let adCount = 1;

        if (maxAds === 0 || !shouldDisplayMobileAds) return videoListCopy;

        for (let i = 0; i < newListLength; i++) {
            if (i < firstAdPlace) {
                newList.push(videoListCopy.shift());
            } else if (i === firstAdPlace && numberOfAds > 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else if (numberOfAds > 0 && (i - firstAdPlace) % (repeatAds + 1) === 0) {
                newList.push(`${baseId}${adCount}`);
                numberOfAds--;
                adCount++;
            } else {
                newList.push(videoListCopy.shift());
            }
        }
        return newList;
    }

    public render() {
        const { videoList, isTabVisible, tabTitleText, hideMoreButton, moreButtonText, domain, shouldDisplayAdsenseAds } = this.props;
        const { isFeedDisplay, initialFeedSlide, feedActiveSlide, togglePromoActive, currentPromoIndex, promoViewportWidth, lastIntersectedPromoIndex } = this.state;
        const basePromoWidthFit375 = 283;
        const promoWidth = (promoViewportWidth / 375) * basePromoWidthFit375;
        const bottomText = moreButtonText && moreButtonText.length > 0 ? moreButtonText : `עוד ב${tabTitleText}`
        const feedDisplaySettings = {
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: initialFeedSlide,
            autoplay: false,
            vertical: true,
            onSwipe: () => { this.onChangeVideoHandler() },
            verticalSwiping: true,
            beforeChange: (current, next) => {
                const userSwipeDirection = next > current ? "up" : "down";
                this.pushGA4Event(`swipe_${userSwipeDirection}`, true);
            },
            afterChange: (current) => {
                this.setState({ feedActiveSlide: current })
                this.onStopPlayingVideoHandler();
            },
        };

        return (
            <div className="videoGalleryVerticalMutamWrapper" ref={componentWrapperRef => (this.componentWrapperRef = componentWrapperRef)}>
                {isFeedDisplay ?
                    <div className="feedDisplay">
                        <Slider {...feedDisplaySettings} className="slider" ref={(sliderRef) => (this.sliderRef = sliderRef)}>
                            {this.getListWithAds().map((videoItem, index) => {
                                const arrowDisplay = index === this.getListWithAds().length - 1 ? "none" : "";
                                if ((typeof videoItem) === 'string') {
                                    return (
                                        <div className="adsWrapper">
                                            <div key={index} id={videoItem} className="ads_vertical_video" dangerouslySetInnerHTML={{
                                                __html: shouldDisplayAdsenseAds ? ` <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4207657971126930" crossorigin="anonymous"></script>
                                    <ins class="adsbygoogle responsive_ins"
                                         style="display: block;"
                                         data-ad-client="ca-pub-4207657971126930"
                                         data-ad-slot="2924927379"
                                         data-full-width-responsive="true"></ins>
                                    <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>` : ""
                                            }}>
                                            </div>
                                            <div className={`arrow arrowNext ${arrowDisplay}`} onClick={this.onNextSlide}>לסרטון הבא</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <VideoVerticalFeedItemMutam
                                            videoItem={videoItem}
                                            key={videoItem.itemId}
                                            initialFeedSlide={initialFeedSlide}
                                            feedActiveSlide={feedActiveSlide}
                                            feedIndex={index}
                                            onCloseVideoGalleryView={this.onCloseVideoGalleryView}
                                            arrowDisplay={arrowDisplay}
                                            onNextSlide={this.onNextSlide}
                                            domain={domain}
                                            index={index} />
                                    )
                                }
                            })}
                        </Slider>
                    </div>
                    :
                    <>
                        {isTabVisible && <div className="videoGalleryVerticalTab componentaTab"><span className="tabIcon"></span><span>{tabTitleText}</span></div>}
                        <PromoCarousel
                            imageWidth={promoWidth}
                            width={640}
                            itemListLength={videoList.length}
                            marginRight={0}
                            togglePromoActive={togglePromoActive}
                            currentPromoIndex={currentPromoIndex}
                            videoList={videoList}
                            isMobileWeb={true}
                            pushGA4Event={this.pushGA4Event}
                        >
                            {videoList.map((videoItem, index) => {
                                return (
                                    <VideoGalleryVerticalItemMutam
                                        videoItem={videoItem}
                                        onOpenVideoGalleryView={this.onOpenVideoGalleryView}
                                        videoIndex={index}
                                        key={videoItem.itemId}
                                        onVideoEnded={this.onVideoEnded}
                                        getItemIntoViewIndex={this.getItemIntoViewIndex}
                                        onLastIntersectingPromoCHange={this.onLastIntersectingPromoCHange}
                                        lastIntersectedPromoIndex={lastIntersectedPromoIndex}
                                        pushGA4Event={this.pushGA4Event}
                                    />
                                )
                            })}
                        </PromoCarousel >
                        {!hideMoreButton && <div className="moreButton" onClick={this.onMoreVideosHandler}>{bottomText}</div>}
                    </>
                }
            </div>
        )
    }
}
